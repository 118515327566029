import React from 'react';
import { LoadMore } from 'components/atoms/LoadMore';
import { Flex, Box } from 'rebass/styled-components';

export const TableLoader = () => (
  <Box sx={{ position: 'sticky', bottom: 0, left: 0, right: 0, zIndex: 1000, bg: 'white' }}>
    <Box style={{ padding: 0, border: 'none' }}>
      <Flex justifyContent="center" alignItems="center" width="100%" py={3}>
        <LoadMore />
      </Flex>
    </Box>
  </Box>
);
