import {
  FloatingOverlay,
  FloatingPortal,
  autoPlacement as autoPlacementMiddleware,
  offset as setOffset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import React, { cloneElement, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';

export const Dropdown = forwardRef(
  (
    { trigger, triggerProps, placement, autoPlacement, offset, extraOptions, onOpen, onClose, children, ...rest },
    ref,
  ) => {
    const didMount = useRef(false);
    const [isOpen, setIsOpen] = useState(false);
    const middleware = [setOffset(offset), shift()];

    if (autoPlacement) {
      middleware.push(autoPlacementMiddleware());
    }

    const { x, y, strategy, refs, context, update } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      placement,
      middleware,
      strategy: 'fixed',
      ...extraOptions,
    });

    useEffect(() => {
      if (!didMount.current) {
        didMount.current = true;
      } else if (isOpen && onOpen) {
        onOpen();
      } else if (!isOpen && onClose) {
        onClose();
      }

      const handleScroll = () => {
        update();
      };

      if (isOpen) {
        window.addEventListener('scroll', handleScroll, true);
      }

      return () => {
        window.removeEventListener('scroll', handleScroll, true);
      };
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    const openDropdown = () => {
      setIsOpen(true);
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      openDropdown,
      closeDropdown,
    }));

    const click = useClick(context);
    const role = useRole(context);
    const dismiss = useDismiss(context, {
      bubbles: {
        outsidePress: false,
      },
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);

    const referenceProps = getReferenceProps();

    const onClick = (e) => {
      if (triggerProps.onClick) {
        triggerProps.onClick(e);
      }

      if (referenceProps.onClick) {
        referenceProps.onClick(e);
      }
    };

    return (
      <>
        {cloneElement(trigger, { ref: refs.setReference, ...triggerProps, ...getReferenceProps(), onClick })}
        {isOpen && (
          <FloatingOverlay>
            <FloatingPortal>
              <Box
                ref={refs.setFloating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  zIndex: 50,
                }}
                {...rest}
                {...getFloatingProps()}
              >
                {children}
              </Box>
            </FloatingPortal>
          </FloatingOverlay>
        )}
      </>
    );
  },
);

Dropdown.propTypes = {
  autoPlacement: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  trigger: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  offset: PropTypes.number,
  extraOptions: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  triggerProps: PropTypes.object,
};

Dropdown.defaultProps = {
  autoPlacement: false,
  placement: 'bottom',
  offset: 4,
  extraOptions: {},
  onOpen: null,
  onClose: null,
  triggerProps: {},
};
