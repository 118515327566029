import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TableWrapper } from './tableWrapper';

export const TableWrapperWithScroll = ({ children, ...props }) => {
  const tableWrapperRef = useRef(null);

  useEffect(() => {
    const viewport = tableWrapperRef.current.querySelector('[data-test-id="virtuoso-scroller"]');
    const handleScroll = (e) => {
      if (e.target.scrollLeft > 0) {
        e.target.classList.add('is-scrolling');
        return;
      }
      e.target.classList.remove('is-scrolling');
    };

    if (viewport) {
      viewport.addEventListener('scroll', handleScroll);
    }

    return () => viewport.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <TableWrapper ref={tableWrapperRef} className="table-wrapper" {...props}>
      {children}
    </TableWrapper>
  );
};

TableWrapperWithScroll.propTypes = {
  children: PropTypes.node.isRequired,
};
