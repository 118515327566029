import { useState } from 'react';

export const useSort = ({ columnDefinition, initialSortBy, onSort }) => {
  const initialSortByColumn = columnDefinition.find((column) => column.accessor === initialSortBy.id);

  const [selectedColumn, setSelectedColumn] = useState({
    value: initialSortByColumn,
    label: initialSortByColumn.Header,
  });

  const handleSort = (value, column) => {
    onSort([
      {
        id: column.accessor,
        desc: value === 'desc',
        column,
      },
    ]);
  };

  return { selectedColumn, setSelectedColumn, handleSort };
};
