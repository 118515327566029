import React from 'react';
import { Flex, Image, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { activeNetworkSelector } from 'redux/selectors';
import { Button } from '@getro/rombo';
import { Plus } from 'lucide-react';
import pluralize from 'pluralize';
import HandEmptyIcon from '../../../assets/icon-magic-wand.svg';
import { GeneratingMatchesLoader } from './generatingMatchesLoader';

export const MatchesNoResults = ({ status, contactCount }) => {
  const history = useHistory();
  const activeNetwork = useSelector(activeNetworkSelector);

  if (status === 'generating_matches') {
    return (
      <Flex mt="40px" flexDirection="column" alignItems="flex-start" maxWidth="520px" mx="auto" textAlign="center">
        <GeneratingMatchesLoader />
        <Text mt="16px" fontWeight="semibold">
          {contactCount > 0
            ? `Analyzing your ${Number(contactCount).toLocaleString()} ${pluralize('contact', contactCount)}...`
            : 'Analyzing your contacts...'}
        </Text>
        <Text textAlign="left" mb="16px" mt="8px" color="text.subtle">
          Just a few seconds of AI magic to save you hours and reveal hidden gems in your network.{' '}
        </Text>
        <Text textAlign="left" mb="16px" mt="16px" color="text.subtle">
          The matches will show here in a few seconds.{' '}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex mt="40px" flexDirection="column" alignItems="center" maxWidth="520px" mx="auto" textAlign="center">
      <Image src={HandEmptyIcon} width="64px" maxWidth="64px" />
      <Text mt="16px" fontWeight="semibold">
        We couldn&apos;t find any match for this job
      </Text>
      <Text mb="16px" mt="8px" color="text.subtle">
        Remember that adding all your team’s contacts increases the chances of finding good matches.
      </Text>
      <Button
        onClick={() => history.push(`/networks/${activeNetwork.slug}/import-contacts`)}
        data-testid="button-matches-project"
        variant="secondary"
        icon={Plus}
        iconGap="8px"
        size="small"
      >
        Add contacts
      </Button>
    </Flex>
  );
};

MatchesNoResults.defaultProps = {
  status: 'active',
};

MatchesNoResults.propTypes = {
  status: PropTypes.oneOf(['generating_matches', 'active', 'closed']),
  contactCount: PropTypes.number.isRequired,
};
