import { Flex } from 'rebass/styled-components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { DataTable } from 'components/molecules/dataTable';
import RenewJobModal from '../../molecules/renewJobModal';
import ForceJobVisibilityModal from '../../molecules/forceJobVisibilityModal';
import { toggleJobActionDialog } from '../../../hooks/useJobActions/actions';
import useJobActions from '../../../hooks/useJobActions';
import { activeNetworkSelector } from '../../../redux/selectors';
import { ShareJobModal } from '../../molecules/shareJobModal';
import { CloseJobModal } from '../../molecules/closeJobModal';
import { DeleteJobModal } from '../../molecules/deleteJobModal';
import FeatureJobModal from '../../molecules/featureJobModal';
import { isNetworkAdminSelector } from '../../../hooks/usePermissions/selectors';
import { getJobsColumns } from './jobColumns';
import JobRulesModal from './jobRulesModal';
import DownloadApplicants from './downloadApplicants';
import { useTableRows } from './hooks/useTableRows';
import { useActionItems } from './hooks/useActionItems';
import { useJobTableFunctions } from './hooks/useJobTableFunctions';

export const JobsDataTable = ({
  isJobsLoading,
  isJobsInitialized,
  totalJobs,
  loadNextPage,
  history,
  jobs,
  hasMoreJobs,
  onSort,
  emptyView,
  initialSortBy,
  source,
  showListColumn,
}) => {
  const { jobActions, jobActionsLoading, jobActionsErrors, dialogStates } = useJobActions({ source });
  const [selectedJob, setSelectedJob] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const activeNetwork = useSelector(activeNetworkSelector);
  const isNetworkAdmin = useSelector(isNetworkAdminSelector);
  const jobsColumns = getJobsColumns(new Set(showListColumn ? [] : ['list']));
  const {
    createMatches,
    isCreatingList,
    getJobById,
    selectAllJobs,
    onSelect,
    isJobSourcePostedActiveOrExpired,
    handleSort,
    onColumnAction,
    onShowJobRulesClick,
    showJobRules,
    setShowJobRules,
  } = useJobTableFunctions({ jobs, onSort });

  const tableRows = useTableRows({
    jobs,
    onShowJobRulesClick,
    selectedJobId: selectedJob?.id,
    createMatches,
    isCreatingList,
    onListClicked: (list) => history.push(`/networks/${activeNetwork.slug}/list/${list.id}/contacts`),
  });

  const actionMenuItems = useActionItems({
    jobActionsLoading,
    isJobSourcePostedActiveOrExpired,
    getJobById,
    jobActions,
    activeNetwork,
    source,
    isNetworkAdmin,
    setSelectedJob,
  });

  return (
    <Flex flexDirection="column" flexGrow="1" sx={{ zIndex: 1, position: 'relative' }}>
      <DataTable
        totalItems={totalJobs}
        canSort
        canSelect={isNetworkAdmin}
        onSelect={onSelect}
        selectedAll={selectAllJobs}
        initialSortBy={initialSortBy}
        onSort={handleSort}
        loadMore={loadNextPage}
        items={tableRows}
        columnDefinition={jobsColumns}
        hasMore={hasMoreJobs}
        isInitialized={isJobsInitialized}
        isLoadingMore={isJobsInitialized && isJobsLoading}
        emptyView={emptyView}
        onColumnAction={onColumnAction}
        persistColumnKey={`${activeNetwork.id}-jobs-columns`}
        tableSx={{
          minWidth: '2000px',
          height: 'fit-content!important',
        }}
        rowActions={{
          height: 330,
          items: actionMenuItems,
        }}
        sx={{
          height: `calc(100vh - ${source === 'employer_profile' ? '243px' : '178px'})!important`,
        }}
      />
      <RenewJobModal
        isOpen={dialogStates.extendJob}
        title={selectedJob?.title}
        company={selectedJob?.organization}
        onClose={() => dispatch(toggleJobActionDialog({ dialog: 'extendJob', isOpen: false }))}
        onSubmit={(e) => {
          jobActions.onExtendJob({ job: selectedJob, extension: e });
        }}
        isSubmitting={jobActionsLoading.isExtending}
        error={jobActionsErrors.extendJob}
      />
      {isNetworkAdmin && (
        <ForceJobVisibilityModal
          isOpen={dialogStates.forceJobVisibility}
          title={`Set ${selectedJob?.title} job visibility `}
          visibilityValue={selectedJob?.visibility}
          company={selectedJob?.organization}
          onClose={() => dispatch(toggleJobActionDialog({ dialog: 'forceJobVisibility', isOpen: false }))}
          onSubmit={(v) => {
            jobActions.onForceJobVisibility({
              job: selectedJob,
              forcedVisibility: v,
              visibilityFilter: qs.parse(location.search).visibility,
            });
          }}
          isSubmitting={jobActionsLoading.isForcingVisibility}
          error={jobActionsErrors.forceJobVisibility}
        />
      )}
      {isNetworkAdmin && (
        <FeatureJobModal
          isOpen={dialogStates.featureJob}
          title="Update job feature status"
          featureJobValue={selectedJob?.featured}
          onClose={() => dispatch(toggleJobActionDialog({ dialog: 'featureJob', isOpen: false }))}
          onSubmit={(v) => {
            jobActions.onFeatureJob({
              job: selectedJob,
              featured: v,
              featuredFilter: qs.parse(location.search).featured,
            });
          }}
          isSubmitting={jobActionsLoading.isFeaturingJob}
          error={jobActionsErrors.featureJob}
        />
      )}
      <ShareJobModal
        job={selectedJob}
        isOpen={dialogStates.shareJob}
        onCancel={() => {
          dispatch(toggleJobActionDialog({ dialog: 'shareJob', isOpen: false }));
        }}
      />
      <CloseJobModal
        job={setSelectedJob}
        isOpen={dialogStates.closeJob}
        onCancel={() => {
          dispatch(toggleJobActionDialog({ dialog: 'closeJob', isOpen: false }));
        }}
        isSubmitting={jobActionsLoading.isClosing}
        error={jobActionsErrors.closeJob}
        onSubmit={() => {
          jobActions.onCloseJob({
            jobId: selectedJob.id,
            networkId: activeNetwork.id,
            isNetworkManager: activeNetwork.isManager,
            companyId: selectedJob.organization.id,
          });
        }}
      />
      <DeleteJobModal
        isDeleting={jobActionsLoading.isDeleting}
        isClosing={jobActionsLoading.isClosing}
        isOpen={dialogStates.deleteJob}
        onCancel={() => {
          dispatch(toggleJobActionDialog({ dialog: 'deleteJob', isOpen: false }));
        }}
        onCloseJob={() => {
          jobActions.onDeleteJob({
            jobId: selectedJob.id,
            networkId: activeNetwork.id,
            isNetworkManager: activeNetwork.isManager,
            companyId: selectedJob.organization.id,
          });
        }}
        onDeleteJob={() => {
          jobActions.onDeleteJob({ job: selectedJob });
        }}
      />
      <DownloadApplicants isOpen={dialogStates.jobApplicationDownload} />
      {showJobRules && <JobRulesModal onCancel={() => setShowJobRules(false)} />}
    </Flex>
  );
};

JobsDataTable.propTypes = {
  isJobsLoading: PropTypes.bool,
  isJobsInitialized: PropTypes.bool,
  totalJobs: PropTypes.number,
  loadNextPage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  jobs: PropTypes.array,
  hasMoreJobs: PropTypes.bool,
  onSort: PropTypes.func,
  emptyView: PropTypes.node,
  initialSortBy: PropTypes.shape({
    id: PropTypes.string.isRequired,
    desc: PropTypes.bool.isRequired,
  }),
  source: PropTypes.string,
  showListColumn: PropTypes.bool,
};

JobsDataTable.defaultProps = {
  isJobsLoading: false,
  isJobsInitialized: false,
  totalJobs: 0,
  jobs: [],
  hasMoreJobs: false,
  onSort: () => {},
  emptyView: null,
  initialSortBy: { id: 'expirationDate', desc: false },
  source: 'jobs',
  showListColumn: false,
};
