import { useLocation, useHistory } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import isEqual from 'lodash.isequal';
import { defaultFilters } from '../../../components/organisms/contactsFilters/defaultFilters';

const exclude = new Set(['contactId']);

const decodeValue = (item, isSingle = false) => {
  const value = decodeURIComponent(item);

  if (value === 'null' && isSingle) {
    return null;
  }

  if (value === 'undefined' && isSingle) {
    return null;
  }

  if (value === 'false') return false;

  if (value === 'true') return true;

  if (!Number.isNaN(Number(value))) {
    return Number(value);
  }

  return value;
};

export function convertQueryToString({ filters, ...query }) {
  const result = [];

  const encodeQuery = (key, value) => `${key}=${encodeURIComponent(value)}`;
  Object.keys(query).forEach((key) => {
    result.push(encodeQuery(key, query[key]));
  });

  const processObject = (prefix, obj) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      const fullKey = `${prefix}.${key}`;
      if (typeof value === 'object' && value !== null) {
        processObject(fullKey, value);
      } else if (typeof value !== 'undefined' && value !== null) {
        result.push(encodeQuery(fullKey, value));
      }
    });
  };

  const processArray = (prefix, arr) => {
    arr.forEach((item, index) => {
      const fullKey = `${prefix}[${index}]`;
      if (typeof item === 'object' && item !== null) {
        if (Array.isArray(item)) {
          processArray(fullKey, item);
        } else {
          processObject(fullKey, item);
        }
      } else if (typeof item !== 'undefined' && item !== null) {
        result.push(encodeQuery(fullKey, item));
      }
    });
  };

  Object.keys(filters).forEach((key) => {
    const value = filters[key];
    if (Array.isArray(value)) {
      processArray(key, value);
    } else if (typeof value === 'object' && value !== null) {
      processObject(key, value);
    } else if (typeof value !== 'undefined' && value !== null) {
      result.push(encodeQuery(key, value));
    }
  });

  return result.join('&');
}

export function isDefaultQuery(filters) {
  return isEqual(defaultFilters, filters);
}

export function convertStringBackToQuery(queryString) {
  const query = {};
  const filters = {};
  const queryParams = queryString.replace(/^\?/, '').split('&');

  queryParams.forEach((param) => {
    const [key, value] = decodeURIComponent(param).split('=');

    if (exclude.has(key)) return;

    if (/^\w+$/.test(key)) {
      query[key] = decodeValue(value, true);
      return;
    }

    if (/\[\d+\]$/.test(key)) {
      const [filterKey] = key.split(/\[\d+\]/);
      if (!filters[filterKey]) {
        filters[filterKey] = [];
      }
      filters[filterKey].push(decodeValue(value));

      return;
    }

    // if it's an object
    if (/^(?!.*\[\d+\])\w+\.\w+$/.test(key)) {
      const [prop, propValue] = key.split(/\./);
      if (!filters[prop]) {
        filters[prop] = {};
      }
      filters[prop][propValue] = decodeValue(value);

      return;
    }

    //     // if it's an array of object
    if (/\w+\[\d+\]\.\w+$/.test(key)) {
      const [prop, index, propValue] = key.split(/[\\[\].]/).filter(Boolean);
      if (!filters[prop]) {
        filters[prop] = [];
      }

      const indexInt = parseInt(index, 10);

      if (!filters[prop][indexInt]) {
        filters[prop][indexInt] = {};
      }

      filters[prop][indexInt][propValue] = decodeValue(value);
      return;
    }

    const regex = /[[\].]/;
    const [prop, index, indexProp, propValue] = key.split(regex).filter(Boolean);

    const indexInt = parseInt(index, 10);

    if (Number.isNaN(indexInt)) {
      if (!filters[prop]) {
        filters[prop] = {};
      }

      if (!filters[prop][index]) {
        filters[prop][index] = {};
      }

      filters[prop][index][indexProp] = decodeValue(value);
      return;
    }

    if (!filters[prop][indexInt][indexProp]) {
      filters[prop][indexInt][indexProp] = {};
    }

    filters[prop][indexInt][indexProp][propValue] = decodeValue(value);
  });
  return { ...query, filters };
}

export const useContactsSearchParam = (defaultQueryFilters) => {
  const { search } = useLocation();
  const { push } = useHistory();
  const [page, setPage] = useState(1);

  const params = useMemo(() => {
    const { filters, ...others } = search ? convertStringBackToQuery(search) : defaultQueryFilters;

    return {
      ...defaultQueryFilters,
      ...others,
      filters: { ...defaultQueryFilters.filters, ...filters },
    };
  }, [search, defaultQueryFilters]);

  const setSearchParams = useCallback(
    (value) => {
      let newValues = { ...value };
      if (typeof value === 'function') {
        newValues = value(params);
      }

      setPage((current) => {
        if (current !== newValues.page) {
          return newValues.page;
        }

        return current;
      });

      delete newValues.page;

      push({
        search: isEqual(defaultQueryFilters.filters, newValues.filters) ? '' : convertQueryToString(newValues),
      });

      return newValues;
    },
    [defaultQueryFilters.filters, params, push],
  );

  return [{ ...params, page }, setSearchParams];
};
