import React from 'react';
import {
  Button,
  Checkbox,
  FormInput,
  FormSectionTitle,
  FormSelect,
  FormTextEditor,
  Loader,
  Message,
} from '@getro/rombo';
import { FastField, Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';
import { Lock } from 'lucide-react';
import { CompanyDetailsForm } from 'pages/listCreate/components/companyDetailsForm';
import { ReduxMultiFormSelect } from 'pages/listCreate/components/ReduxMultiFormSelect';
import { usePrivateList } from './hooks/usePrivateList';

export const PrivateList = ({ onMatchSelected, matchId, setSmartOption }) => {
  const {
    employmentTypes,
    locations,
    formSchema,
    error,
    isCompanyFromPortfolio,
    setIsCompanyFromPortfolio,
    initialValues,
    match,
    loadingMatch,
    onSubmit,
    isEdit,
  } = usePrivateList({ onMatchSelected, matchId });

  if (isEdit && !match && loadingMatch) {
    return (
      <Flex justifyContent="center" alignItems="center" height="70vh">
        <Loader />
      </Flex>
    );
  }

  return (
    <Box sx={{ maxWidth: '800px', width: '100%', m: '0 auto' }}>
      <Flex sx={{ flexDirection: 'column', gap: '16px' }}>
        <Flex
          sx={{
            'p, svg': { color: 'text.main' },
            borderRadius: '8px',
            backgroundColor: 'neutral.20',
            justifyContent: 'flex-start',
            alignItems: 'center',
            p: '16px',
            mt: '16px',
            gap: '16px',
          }}
        >
          <Box width="16px" height="16px" mr="16px">
            <Box as={Lock} size="16px" />
          </Box>
          <Box as="p" fontWeight="500" lineHeight="1.4" fontSize="14px" mt>
            Enter the details of the job you want to get matches for. It will never be published on your job board or
            shared externally.
          </Box>
        </Flex>
      </Flex>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
        validationSchema={formSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Flex flexDirection="column" sx={{ gap: '24px' }}>
              <Flex flexDirection="column" sx={{ gap: '24px' }}>
                <FormSectionTitle>1. Company details</FormSectionTitle>
                <CompanyDetailsForm
                  values={values}
                  isCompanyFromPortfolio={isCompanyFromPortfolio}
                  setFieldValue={setFieldValue}
                  setIsCompanyFromPortfolio={setIsCompanyFromPortfolio}
                />
              </Flex>
              <Flex
                flexDirection="column"
                sx={{ gap: '24px', '& .public-DraftEditorPlaceholder-inner': { fontSize: '14px!important' } }}
              >
                <FormSectionTitle>2. Job details</FormSectionTitle>
                <FastField
                  component={FormInput}
                  name="title"
                  placeholder="Ex: Senior Account Manager"
                  label="Title"
                  labelExtra="Required"
                />
                <FastField
                  component={FormTextEditor}
                  name="description"
                  placeholder="Enter the position details"
                  label="Job description or skills"
                  helpText="Enter the job description or skills in any format. Our AI will handle the details, and the information will not be shown on your job board."
                  labelExtra="Required"
                />
                <Field
                  component={ReduxMultiFormSelect}
                  name="locations"
                  label="Location"
                  labelExtra="Required"
                  loading={locations.loading}
                  options={locations.options}
                  onInputChange={locations.search}
                  orientation="horizontal"
                  placeholder="Start typing..."
                />
                <Checkbox
                  label="Remote position"
                  name="remote"
                  value={values.remote}
                  checked={values.remote}
                  onChange={() => setFieldValue('remote', !values.remote)}
                />
                <FastField
                  component={FormSelect}
                  name="employmentType"
                  label="Employment type"
                  options={employmentTypes}
                  labelExtra="Required"
                  placeholder="Select"
                />
              </Flex>

              {error && (
                <Message type="error" title="Invalid website format">
                  There was an unexpected error, please try again or contact us.
                </Message>
              )}
              <Flex justifyContent="flex-start" pb="40px">
                <Button type="submit">Save job details</Button>
                <Button variant="tertiary" type="submit" onClick={() => setSmartOption(null)}>
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

PrivateList.defaultProps = {
  onMatchSelected: () => {},
};

PrivateList.propTypes = {
  onMatchSelected: PropTypes.func,
  matchId: PropTypes.string.isRequired,
  setSmartOption: PropTypes.func.isRequired,
};
