import React, { Fragment } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp, EyeOff, MoreHorizontal } from 'lucide-react';
import { Dropdown } from 'components/molecules/dropdown';
import { Card } from 'components/atoms/Card';

export const TableHeaderSort = ({ column, handleSort, sortedBy, hideColumn }) => {
  const sortOptions = [
    { label: 'Sort ascending', value: 'asc', icon: ChevronUp, show: !column.disableSortBy },
    { label: 'Sort descending', value: 'desc', icon: ChevronDown, show: !column.disableSortBy },
    { label: 'Hide', value: 'hide', icon: EyeOff, show: true },
  ].filter((option) => option.show);

  return (
    <Flex id={column.accessor} sx={{ gap: '8px', visibility: 'hidden' }}>
      <Dropdown
        trigger={
          <Flex sx={{ cursor: 'pointer' }} alignItems="center">
            <Box as={MoreHorizontal} size="14" />
          </Flex>
        }
      >
        <Card>
          {sortOptions.map((option) => (
            <Fragment key={option.value}>
              {option.value === 'hide' && !column.disableSortBy && (
                <Box width="100%" height="1px" bg="border.subtle" my="8px" />
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  height: '28px',
                  cursor: 'pointer',
                  '&:hover': { bg: 'neutral.20' },
                }}
                onClick={() => {
                  if (option.value === 'hide') {
                    hideColumn(column);
                    return;
                  }
                  handleSort(option.value, column);
                }}
              >
                <Box as={option.icon} size="16px" />
                <Text color="text.main" fontSize="14px" fontWeight="400" lineHeight="20px">
                  {option.label}
                </Text>
              </Box>
            </Fragment>
          ))}
        </Card>
      </Dropdown>
      {sortedBy.id === column.accessor && (
        <Flex
          onClick={() => {
            handleSort(sortedBy.desc ? 'asc' : 'desc', column);
          }}
          sx={{ flexDirection: 'column' }}
        >
          {sortedBy.desc ? <Box as={ChevronDown} size="16px" /> : <Box as={ChevronUp} size="16px" />}
        </Flex>
      )}
    </Flex>
  );
};

TableHeaderSort.propTypes = {
  handleSort: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  sortedBy: PropTypes.object.isRequired,
  hideColumn: PropTypes.func.isRequired,
};
