/* eslint-disable function-paren-newline */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { Flex, Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { TableItem } from './components/tableItem';
import { TableHeader } from './components/tableHeader';
import { TableColumns } from './components/tableColumns';
import { TableSort } from './components/tableSort';
import { TableLoader } from './components/tableLoader';
import { TableWrapperWithScroll } from './components/tableWithScroll';
import { useSort } from './hooks/useSort';

export const DataTable = ({
  items,
  overscan = 10,
  initialItemCount = 10,
  hasMore = false,
  loadMore = () => {},
  isLoadingMore = false,
  columnDefinition,
  canSort = false,
  onRowProps,
  rowActions,
  initialSortBy,
  totalItems,
  emptyView,
  isInitialized = false,
  sx,
  tableSx,
  onSort,
  onSelect,
  canSelect = false,
  selectedAll = false,
  onColumnAction,
  persistColumnKey,
}) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    persistColumnKey || 'datatable',
    columnDefinition.map((item) => ({
      ...item,
      show: true,
    })),
  );
  const showEmptyView = items.length === 0 && !isLoadingMore && isInitialized;
  const { selectedColumn, setSelectedColumn, handleSort } = useSort({
    columnDefinition: selectedColumns,
    initialSortBy,
    onSort,
  });

  return (
    <TableWrapperWithScroll>
      <Flex sx={{ alignItems: 'center', gap: '8px', mb: '18px' }}>
        <Box color="text.main" fontSize="12px" lineHeight="24px">
          Showing all {totalItems} jobs
        </Box>
        <TableColumns
          selectedColumns={selectedColumns}
          setSelectedColumns={(item, action, column) => {
            setSelectedColumns(item);
            onColumnAction?.(action, { payload: column });
          }}
        />
        {canSort && (
          <TableSort
            selectedSort={{
              value: initialSortBy,
              label: initialSortBy.desc ? 'Descending' : 'Ascending',
            }}
            setSelectedSort={({ value }) => handleSort(value, selectedColumn.value)}
            columns={selectedColumns.filter((column) => !column.disableSortBy)}
            selectedColumn={selectedColumn}
            setSelectedColumn={(column) => {
              setSelectedColumn(column);
              handleSort(initialSortBy.desc ? 'desc' : 'asc', column.value);
            }}
          />
        )}
      </Flex>
      <Box
        as={TableVirtuoso}
        sx={{
          height: '600px!important',
          ...sx,
          ...(showEmptyView || !isInitialized
            ? {
                height: '48px!important',
                overflow: 'hidden',
              }
            : {}),
        }}
        data={!isInitialized ? [] : items}
        overscan={overscan}
        initialItemCount={initialItemCount}
        endReached={() => {
          if (hasMore) {
            loadMore();
          }
        }}
        components={{
          Table: (props) => (
            <Box
              as="table"
              {...props}
              sx={{
                width: '100%',
                height: 'calc(100vh - 240px)',
                ...tableSx,
              }}
            />
          ),
        }}
        fixedHeaderContent={() => (
          <TableHeader
            selectedAll={selectedAll}
            canSelect={canSelect}
            onSelect={onSelect}
            checked={selectedAll}
            columns={selectedColumns.filter((item) => item.show)}
            handleSort={handleSort}
            sortedBy={initialSortBy}
            hideColumn={(column) => {
              onColumnAction('hide', { payload: column });
              setSelectedColumns((prev) =>
                prev.map((item) => {
                  if (item.accessor === column.accessor) {
                    return { ...item, show: false };
                  }
                  return item;
                }),
              );
            }}
          />
        )}
        itemContent={(_, item) => {
          const rowProps = onRowProps ? onRowProps(item) : {};
          if (!item) return null;

          return selectedColumns
            .filter((column) => column.show)
            .map(({ accessor }, index) => (
              <TableItem
                {...rowProps}
                onSelect={onSelect}
                canSelect={canSelect}
                isSticky={index === 0}
                key={accessor}
                row={item}
                item={item[accessor]}
                rowActions={rowActions}
              />
            ));
        }}
      />
      {showEmptyView && (
        <Box>
          <Box style={{ padding: 0, border: 'none' }} colSpan={selectedColumns.filter((item) => item.show).length}>
            <Flex justifyContent="center" alignItems="center" width="100%" py={3}>
              {emptyView}
            </Flex>
          </Box>
        </Box>
      )}

      {(isLoadingMore || !isInitialized) && (
        <TableLoader colSpan={selectedColumns.filter((item) => item.show).length} />
      )}
    </TableWrapperWithScroll>
  );
};

DataTable.propTypes = {
  items: PropTypes.array.isRequired,
  overscan: PropTypes.number,
  initialItemCount: PropTypes.number,
  totalItems: PropTypes.number,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func,
  isLoading: PropTypes.bool,
  columnDefinition: PropTypes.array.isRequired,
  canSort: PropTypes.bool,
  onRowProps: PropTypes.func,
  rowActions: PropTypes.shape({ height: PropTypes.number, items: PropTypes.arrayOf(PropTypes.object) }),
  initialSortBy: PropTypes.shape({ id: PropTypes.string, desc: PropTypes.bool }),
  emptyView: PropTypes.node,
  isInitialized: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  sx: PropTypes.object,
  tableSx: PropTypes.object,
  onSort: PropTypes.func,
  onSelect: PropTypes.func,
  canSelect: PropTypes.bool,
  selectedAll: PropTypes.bool,
  onColumnAction: PropTypes.func,
  persistColumnKey: PropTypes.string,
};

DataTable.defaultProps = {
  overscan: 10,
  initialItemCount: 10,
  hasMore: false,
  totalItems: 0,
  loadMore: () => {},
  isLoading: false,
  canSort: false,
  onRowProps: () => {},
  rowActions: null,
  initialSortBy: { id: 'asc', desc: false },
  emptyView: null,
  isInitialized: false,
  isLoadingMore: false,
  sx: {},
  tableSx: {},
  onSort: () => {},
  onSelect: () => {},
  canSelect: false,
  selectedAll: false,
  onColumnAction: () => {},
  persistColumnKey: null,
};
