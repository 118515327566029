import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { Checkbox } from '@getro/rombo';
import TableActionMenu from './tableActionItems';

export const TableItem = ({ item, isSticky, row, canSelect, onSelect, rowActions }) => {
  const id = `${row.id}-action-menu`;
  return (
    <Box
      as="td"
      sx={{
        position: isSticky ? ['relative', 'sticky'] : 'relative',
        top: 0,
        left: 0,
        bg: row.selected ? 'purple.100' : 'white',
        zIndex: 1,
        cursor: 'initial',
        '&:hover div': {
          visibility: 'visible',
        },
      }}
    >
      <Flex sx={{ gap: '8px' }}>
        {isSticky && canSelect && (
          <Box sx={{ pt: '4px', minWidth: '16px' }}>
            <Checkbox
              name="job"
              value={row.id}
              checked={row.selected}
              onChange={(e) => onSelect(row, e.target.checked)}
              containerProps={{ width: '16px', minWidth: '16px' }}
            />
          </Box>
        )}
        <Box sx={{ gap: '8px', flex: 1 }} display="flex" alignItems="flex-start" justifyContent="space-between">
          {item}
        </Box>
        {isSticky && (
          <Box minWidth="24px" minHeight="24px" id={id} sx={{ visibility: 'hidden' }}>
            <TableActionMenu row={row} isLast={isSticky} rowActions={rowActions} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

TableItem.propTypes = {
  item: PropTypes.node.isRequired,
  isSticky: PropTypes.bool.isRequired,
  row: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  canSelect: PropTypes.bool.isRequired,
  rowActions: PropTypes.array,
};

TableItem.defaultProps = {
  rowActions: [],
};
