/* eslint-disable function-paren-newline */
import { GripHorizontal, Layout } from 'lucide-react';
import React, { useState, useRef, useEffect } from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';
import { Toggle } from '@getro/rombo';
import PropTypes from 'prop-types';
import useOnClickOutside from 'hooks/useClickOutside';

export const TableColumns = ({ selectedColumns, setSelectedColumns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);

  const ref = useOnClickOutside(() => setIsOpen(false), { useEsc: true, downEvent: 'click' });
  const dragItem = useRef();
  const dragOverItem = useRef();

  useEffect(() => {
    const handleDragEnd = () => {
      const newColumns = [...selectedColumns];
      const draggedItemContent = newColumns.splice(dragItem.current, 1)[0];
      newColumns.splice(dragOverItem.current, 0, draggedItemContent);
      dragItem.current = null;
      setSelectedColumns(newColumns, 'reorder', draggedItemContent);
      setDraggedItem(null);
    };

    document.addEventListener('dragend', handleDragEnd);
    return () => document.removeEventListener('dragend', handleDragEnd);
  }, [selectedColumns, setSelectedColumns]);

  const handleDragStart = (e, index) => {
    dragItem.current = index;
    e.dataTransfer.effectAllowed = 'move';
    setDraggedItem(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    dragOverItem.current = index;
  };

  return (
    <Box ref={ref} sx={{ position: 'relative' }}>
      <Flex onClick={() => setIsOpen(!isOpen)} sx={{ alignItems: 'center', gap: '8px', p: '8px', cursor: 'pointer' }}>
        <Box as={Layout} size="16px" />
        <Text sx={{ fontSize: '14px', fontWeight: 500, color: 'text.main', lineHeight: '16px' }}>Columns</Text>
      </Flex>

      {isOpen && (
        <Box
          top="110%"
          left="0"
          minHeight={`${selectedColumns.length * 32 + 16}px`}
          sx={{
            p: '8px',
            width: '240px',
            boxShadow: '0px 2px 32px 0px #1B24320F, 0px 1px 4px 0px #1B24320F',
            position: 'absolute',
            left: '0',
            top: '30px',
            zIndex: 1000,
            backgroundColor: 'white',
          }}
        >
          {selectedColumns.map((column, index) => (
            <Flex
              key={column.accessor}
              sx={{
                p: '4px 8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'move',
                opacity: draggedItem === index ? 0 : 1,
                transform: draggedItem === index ? 'scale(1.05)' : 'scale(1)',
                transition: 'opacity 0.2s, transform 0.2s',
              }}
              data-accessor={column.accessor}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={(e) => handleDragOver(e, index)}
            >
              <Flex sx={{ alignItems: 'center', gap: '8px' }}>
                <Box sx={{ color: 'neutral.400' }} as={GripHorizontal} />
                <Text sx={{ fontSize: '14px', fontWeight: 400, color: 'text.main' }}>{column.Header}</Text>
              </Flex>
              <Toggle
                checked={column.show}
                label={column.Header}
                onChange={() =>
                  setSelectedColumns(
                    (items) =>
                      items.map((item) => {
                        if (item.accessor === column.accessor) {
                          return { ...item, show: !item.show };
                        }
                        return item;
                      }),
                    !column.show ? 'show' : 'hide',
                    column,
                  )
                }
              />
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  );
};

TableColumns.propTypes = {
  selectedColumns: PropTypes.array.isRequired,
  setSelectedColumns: PropTypes.func.isRequired,
};
